@use "src/theme";

.preview {
  // increase the specificity of the selector due to an issue with the device preview class overriding the preview class in production
  &.preview {
    position: absolute;
    width: 320px;
    height: 640px;
    max-height: 1000px;
    z-index: 5;
    top: -120px;
    right: 30px;

    @media (max-width: theme.$mobile-breakpoint-max) {
      display: none;
    }
  }
}


.container {
  transform: perspective(1000px) rotateX(4deg) rotateY(-16deg) rotateZ(4deg);
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.2));
  position: absolute;
  right: 6rem;
  z-index: 1;
}
