.divider {
  background-color: var(--color-border);
  border: none;
  min-height: 1px;
  min-width: 1px;
  // needs to work for either horizontal or vertical
  width: unset;
  height: unset;
  margin: 0.25em;
  padding: 0;

}
