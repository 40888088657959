.root {
  background-color: var(--color-input);
  width: 1.5em;
  height: 1.5em;
  padding: 1em;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--color-border);

  &:disabled {
    color: var(--color-muted-text);
    cursor: not-allowed;
    background: var(--color-input-disabled);
  }
}

.indicator {
  path {
    stroke: currentColor;
  }

  svg {
    display: none;
  }

  &[data-state="indeterminate"] {
    .indeterminate {
      display: block;
    }
  }

  &[data-state="checked"] {
    .checked {
      display: block;
    }
  }
}
