.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color-mix(in srgb, var(--color-background), 10% transparent);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 1rem;
  transition: opacity 200ms ease, visibility 0ms ease 200ms;
  pointer-events: none;
  visibility: hidden;
  flex-direction: column;
  opacity: 0;

  &[data-visible="true"] {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
}
