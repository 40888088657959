@use "src/theme";

.link {
  display: inline-block;
  --color-ring: currentColor;

  @each $name, $var in theme.$color-map {
    &[data-color='#{$name}'] {
      color: var(--color-#{$var});
    }
  }
}


.hyperlink {
  text-decoration: underline;
  color: var(--color-accent);
}

.minimal {
  text-decoration: underline;
}
