.search {
  &::placeholder {
    color: inherit;
  }
}

.icon {
  // spring
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &[data-visible="true"] {
    transform: rotate(180deg);
  }
}
