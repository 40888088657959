.dialogContent {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  z-index: 1;
  @apply p-5;
  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  overflow: hidden;
  width: 800px;
  max-height: 100%;
  max-width: 100%;
}


@keyframes contentShow {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dialogActions {
  border-top: 1px solid var(--color-border);
  padding-top: 1rem;
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @apply gap-2;
}
