$overlay-opacity: 0.5;

.modalOverlay {
  background-color: var(--color-dark);
  opacity: $overlay-opacity;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  cursor: pointer;
  overflow: hidden;
}

.modalPortal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  overflow: hidden;
}


@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: $overlay-opacity;
  }
}

