.tabs {
  &.variant-normal {
    .tab {
      @apply p-2;

      &:hover {
        @apply bg-muted;
      }
    }
  }
}

.indicatorBar {
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  @apply bg-primary;
  // spring
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  display: none;

  &.ssr {
    display: block;
    opacity: 1;
    width: 100%;
    bottom: -4px;
  }
}

.tab {
  position: relative;
  font-weight: 500;

  &:focus {
    z-index: 1;
  }

  &[data-state="active"] {
    @apply text-primary;
  }

  > svg {
    width: 1.25em;
    height: 1.25em;
  }
}
