.item {
  padding: 0.5rem 0.5rem;
  position: relative;
  border-radius: var(--length-radius);
  justify-content: space-between;
  display: flex;
  overflow-x: hidden;
  overflow-y: visible;
  width: 100%;
  min-height: 2em;
  outline-offset: -2px !important;

  .selectedIcon {
    display: none;
  }

  &:focus {
    z-index: 1;
  }

  &:hover {
    background: var(--color-muted);
  }


  &[data-state="checked"], &[data-selected="true"] {
    .selectedIcon {
      margin-left: 0.5rem;
      display: block;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;

}

.label {
  padding: 0.5rem;
  font-weight: bold;
}
