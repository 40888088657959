@use "sass:color";
@use "sass:math";

@mixin device-screen($scale: 2) {
  height: 100% * $scale;
  width: 100% * $scale;
  scale: math.div(1,  $scale);
  top: -50% * ($scale - 1);
  left: -50% * ($scale - 1);
}

// Reset
.device {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
    display: block;
  }
}

.device {
  position: relative;
  transform: scale(1);
  z-index: 1;

  .deviceFrame {
    z-index: 1;
  }

  .deviceScreen {
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    position: relative;
    overflow: hidden;
    @include device-screen(1.5);

    [data-device="mobile"] & {
      padding-top: 5%;
    }
  }
}

$dark-color: #121212 !default;
$light-color: #fff !default;
$device-panel: #0d0d0d;

// MacBook Pro (2022)
$device-silver: #e2e3e4;
$device-silver-dark: color.adjust($device-silver, $lightness: -10%, $space: hsl);
$device-silver-panel: #0d0d0d;
$device-spacegray: #83878a;
$device-spacegray-dark: color.adjust($device-spacegray, $lightness: -5%, $space: hsl);
$device-spacegray-panel: #0d0d0d;

.deviceMacbookPro {
  $width: 740px;
  $height: 434px;
  width: 100%;
  aspect-ratio: math.div($width, $height);
  max-width: 100%;
  max-height: 100%;

  .deviceFrame {
    background: $device-silver-panel;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px $device-silver-dark;
    margin: 0 auto;
    padding: math.div(9px, $height) * 100% math.div(9px, $width) * 100% math.div(16px, $height) * 100% math.div(9px, $width) * 100%;
    position: relative;
    //width: 618px;
    width: math.div(618px, $width) * 100%;
    //height: 418px;
    height: math.div(418px, $height) * 100%;


  }

  .deviceHeader {
    background: $device-silver-panel;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 12px;
    left: 50%;
    margin-left: math.div(-32px, $width) * 100%;
    position: absolute;
    top: math.div(11px, $height) * 100%;
    width: math.div(64px, $width) * 100%;
    z-index: 2;
    transform: translateY(-1px);

    &::after,
    &::before {
      content: "";
      height: 4px;
      position: absolute;
      top: 0;
      width: 4px;
    }

    &::after {
      background: radial-gradient(circle at bottom left, transparent 0, transparent 75%, $device-silver-panel 75%, $device-silver-panel 100%);
      left: -4px;
    }

    &::before {
      background: radial-gradient(circle at bottom right, transparent 0, transparent 75%, $device-silver-panel 75%, $device-silver-panel 100%);
      right: -4px;
    }
  }

  // 3456-by-2234-pixel resolution
  .deviceScreen {
    border: 2px solid color.adjust($device-silver-panel, $lightness: 2%, $space: hsl);
    border-radius: 10px 10px 0 0;
  }

  .devicePower {
    background: radial-gradient(circle at center, $device-silver 85%, $device-silver-dark 100%);
    border: solid color.adjust($device-silver, $lightness: -25%, $space: hsl);
    border-radius: 2px 2px 12px 12px;
    border-width: 1px 2px 0 2px;
    box-shadow: inset 0 -2px 8px 0 color.adjust($device-silver-dark, $lightness: -35%, $space: hsl);
    height: math.div(24px, $height) * 100%;
    margin-top: -(math.div(10px, $height)) * 100%;
    position: relative;
    width: 100%;
    z-index: 9;

    &::after {
      background: color.adjust($device-silver-dark, $lightness: 10%, $space: hsl);
      border-radius: 0 0 10px 10px;
      box-shadow: inset 0 0 4px 2px color.adjust($device-silver-dark, $lightness: -5%, $space: hsl);
      content: "";
      height: 50%;
      left: 50%;
      margin-left: -60px;
      position: absolute;
      top: 0;
      width: 20%;
    }

    &::before {
      background: transparent;
      border-radius: 0 0 3px 3px;
      bottom: -2px;
      //box-shadow: -300px 0 lighten($device-silver-panel, 10%), 300px 0 lighten($device-silver-panel, 10%);
      content: "";
      height: 2px;
      left: 50%;
      margin-left: -20px;
      position: absolute;
      width: 40px;
    }
  }

  // Space Gray edition
  &.deviceSpacegray {
    .deviceFrame {
      box-shadow: inset 0 0 0 2px $device-spacegray-dark;
    }

    .devicePower {
      background: radial-gradient(circle at center, $device-spacegray 85%, $device-spacegray-dark 100%);
      border-color: color.adjust($device-spacegray, $lightness: -25%, $space: hsl);
      box-shadow: inset 0 -2px 8px 0 color.adjust($device-spacegray-dark, $lightness: -35%, $space: hsl);

      &::after {
        background: color.adjust($device-spacegray-dark, $lightness: 25%, $space: hsl);
        box-shadow: inset 0 0 4px 2px color.adjust($device-spacegray-dark, $lightness: -5%, $space: hsl);
      }
    }
  }
}

// iPhone X
$device-silver: #e2e3e4;
$device-silver-dark: color.adjust($device-silver, $lightness: -10%, $space: hsl);
$device-silver-panel: #222;

.deviceIphoneX {
  $width: 458px;
  $height: 868px;

  height: $height;
  max-height: 100%;
  aspect-ratio: math.div($width, $height);
  position: relative;

  .deviceFrame {
    background: $device-silver-panel;
    border-radius: 68px;
    box-shadow: inset 0 0 2px 2px $device-silver-dark, inset 0 0 0 7px $device-silver;
    height: 100%;
    padding: 33px  28px;
    width: 100%;
  }

  // 2436-by-1125-pixel resolution
  .deviceScreen {
    border-radius: 40px;
    @include device-screen(1);
  }

  .deviceStripe {
    &::after,
    &::before {
      border: solid rgba($device-panel, .25);
      border-width: 0 7px;
      content: "";
      height: math.div(7px, $height)  * 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 9;
    }

    &::after {
      top: math.div(85px, $height)  * 100%;
    }

    &::before {
      bottom: math.div(85px, $height)  * 100%;
    }
  }

  .deviceHeader {
    // disable notch
    display: none;
    background: $device-silver-panel;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: math.div(30px, $height)  * 100%;
    left: 50%;
    position: absolute;
    top: 28px;
    width: math.div(204px, $width) * 100%;
    max-width: 204px;
    transform: translateX(-50%);

    &::after,
    &::before {
      content: "";
      height: 10px;
      position: absolute;
      top: 0;
      width: 10px;
    }

    &::after {
      background: radial-gradient(circle at bottom left, transparent 0, transparent 75%, $device-silver-panel 75%, $device-silver-panel 100%);
      left: -10px;
    }

    &::before {
      background: radial-gradient(circle at bottom right, transparent 0, transparent 75%, $device-silver-panel 75%, $device-silver-panel 100%);
      right: -10px;
    }
  }

  .deviceSensors {
    &::after,
    &::before {
      content: "";
      position: absolute;
    }

    &::after {
      background: #333;
      border-radius: 3px;
      height: 6px;
      left: 50%;
      margin-left: -25px;
      top: 32px;
      width: 50px;
    }

    &::before {
      background: radial-gradient(farthest-corner at 20% 20%, #6074BF 0, transparent 40%),
      radial-gradient(farthest-corner at 80% 80%, #513785 0, #24555E 20%, transparent 50%);
      box-shadow: 0 0 1px 1px rgba($light-color, .05);
      border-radius: 50%;
      height: 10px;
      left: 50%;
      margin-left: 42px;
      top: 30px;
      width: 10px;
    }
  }

  .deviceBtns {
    background: $device-silver-dark;
    height: 32px;
    left: -3px;
    position: absolute;
    top: 115px;
    width: 3px;

    &::after,
    &::before {
      background: $device-silver-dark;
      content: "";
      height: 62px;
      left: 0;
      position: absolute;
      width: 3px;
    }

    &::after {
      top: 60px;
    }

    &::before {
      top: 140px;
    }
  }

  .devicePower {
    background: $device-silver-dark;
    height: 100px;
    right: -3px;
    position: absolute;
    top: 200px;
    width: 3px;
  }
}
