@use "src/theme";

@keyframes success {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  to {
    transform: scale(1);
  }
}

.button {
  padding: 0.5em 1em;
  border-radius: var(--length-radius-large);
  position: relative;
  //line-height: 0.7lh;
  min-height: theme.$line-height;
  background: var(--color-button);
  color: var(--color-button-foreground);
  --color-ring: var(--color-button);
  --color-button: var(--color-primary);
  --color-button-foreground: var(--color-primary-foreground);
  //font-weight: 500;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  overflow: hidden;
  text-align: center;
  font-family: var(--font-body-family), sans-serif;

  &.success {
    animation: success 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  svg {
    pointer-events: none;
  }

  &.size-large {
    font-size: 1.25rem;
  }

  &.size-medium {
    font-size: 1rem;
  }

  &.size-small {
    font-size: 0.875rem;
  }


  :global(.ink) {
    transition: opacity 200ms ease;
  }

  &.disabled, &.loading {
    cursor: not-allowed !important;
    --color-button: var(--color-input-disabled) !important;
    --color-button-foreground: var(--color-muted-text) !important;
    background: var(--color-button) !important;
    color: var(--color-button-foreground) !important;

    &.variant-bare {
      background: transparent !important;
    }

    :global(.ink) {
      opacity: 0;
    }
  }

  > svg {
    width: 1.25em;
    height: 1.25em;
  }

  &.loading {
    cursor: wait !important;


    // shimmer animation
    &:before {
      content: '';
      position: absolute;
      inset: 0;
      pointer-events: none;
      opacity: 0.2;

      background: linear-gradient(
          to right,
          transparent 0%,
          currentColor 50%,
          transparent 100%
      );
      animation: shimmer 1s infinite linear;

      // spring animation
      transform-origin: left;
    }
  }

  &.variant-outline {
    background: transparent;
    color: var(--color-button);
    --color-ring: var(--color-button);

    &:before {
      content: '';
      position: absolute;
      inset: 0px;
      border-radius: inherit;
      pointer-events: none;
      border: 1px solid var(--color-button);
    }
  }

  &.variant-bare {
    background: transparent;
    --color-button: currentColor;
    --color-button-foreground: currentColor;
    color: var(--color-button);
    --color-ring: currentColor;

  }


  @each $name, $var in theme.$color-map {
    &.color-#{$name} {
      --color-button: var(--color-#{$var});
      --color-button-foreground: var(--color-#{$var}-foreground);
    }
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.successOverlay {
  position: absolute;
  inset: 0;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-success);
  pointer-events: none;
}
