@keyframes glow {
  0% {
    margin: -3px;
    opacity: 0.1;
  }
  50% {
    margin: -5px;
    opacity: 0.2;
  }
  100% {
    margin: -3px;
    opacity: 0.1;
  }
}

.glow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  animation: glow 2s infinite;
  border-radius: var(--length-radius);
  margin: -5px;
}
