$input-icon-padding: 2.5rem;

.fontPicker {
  background: transparent;
  border: none;
  position: relative;

  &:focus-within {
    .preview {
      display: none;
    }
  }
}


.search {
  width: 100%;
  padding-left: $input-icon-padding !important;

  &:not(:focus) {
    color: transparent !important;
  }
  
  &:disabled {
    color: transparent !important;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}

.search:focus {
  cursor: text;
  color: inherit;
}

.dropdown {
  border: 1px solid var(--color-border);
  min-height: calc(12em + 1px);
  height: 50%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 !important;
  display: block !important;
  //scale: 0 0.001;
}

.fontpicker__popout.fontpicker__active {
  opacity: 1;
  transform: scale(1);
}

.option.selected {
  background: var(--color-primary);
  color: var(--color-primary-foreground);
}

.fontpicker__nomatches {
  padding: 0.8rem;
  line-height: 2em;
  text-align: center;
  color: var(--color-muted-text);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.previewContainer {
  &.disabled {
    opacity: 0.45;
  }
}

.preview {
  position: absolute;
  inset: 0;
  left: $input-icon-padding;

  &.image {
    transform: translateX(-8px);
  }


  pointer-events: none;
  display: flex;
  align-items: center;

  &.fadeIn {
    animation: fade-in 0.2s ease-in-out forwards;
  }

  &.fadeOut {
    animation: fade-out 0.2s ease-in-out forwards;
  }
}

.inputIcon {
  position: absolute;
  top: 50%;
  left: 0.5em;
  transform: translateY(-50%);
  pointer-events: none;

  &.disabled {
    color: var(--color-muted-text);
  }
}
