.transparentButton {
  --color-ring: var(--color-border);
  background: white;
  background-image: linear-gradient(45deg, var(--color-muted) 25%, transparent 25%), linear-gradient(-45deg, var(--color-muted) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--color-muted) 75%), linear-gradient(-45deg, transparent 75%, var(--color-muted) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  color: var(--color-dark);
}

.colorButton {
  @apply border;
  padding: 0;
  $size: 2.5rem;
  width: $size;
  height: $size;
  border-radius: 999px;
  position: relative;

  &:disabled {

    &:after {
      content: '';
      position: absolute;
      inset: 0px;
      opacity: 0.8;
      background: var(--color-input-disabled);
    }
  }
}

